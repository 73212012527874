.bill-description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
}
.pie-chart-container {
  width: 300px; /* Set your desired width */
  height: 300px; /* Set your desired height */
  margin-left: 20px;
}
.chart-container {
  display: flex; /* Use flex to position the pie chart and legend side by side */
  align-items: center; /* Center items vertically */
}

.pie-chart-container {
  width: 300px; /* Set your desired width */
  height: 300px; /* Set your desired height */
  margin-right: 20px; /* Space between the pie chart and legend */
}

.legend-container {
  display: flex;
  flex-direction: column; /* Stack legend items vertically */
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Space between legend items */
}

.legend-color {
  width: 15px; /* Width of the color box */
  height: 15px; /* Height of the color box */
  border-radius: 3px; /* Optional: round corners */
  margin-right: 5px; /* Space between color box and label */
}
